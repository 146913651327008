/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
  personal: {
    title: '',
    step: {
      label: 'Persönliche Daten',
    },
    formFields: {
      salutation: {
        label: 'Anrede',
        options: {
          female: {
            label: 'Frau',
          },
          male: {
            label: 'Herr',
          },
          nonbinary: {
            label: 'Nicht binär',
          },
          married: {
            label: 'Eheleute',
          },
          family: {
            label: 'Familie',
          },
        },
      },
      title: {
        label: 'Titel',
        options: {
          none: {
            label: 'Keiner',
          },
          dr: {
            label: 'Dr.',
          },
          prof: {
            label: 'Prof.',
          },
          profDr: {
            label: 'Prof. Dr.',
          },
        },
      },
      firstname: {
        label: 'Vorname',
      },
      lastname: {
        label: 'Nachname',
      },
      phone: {
        label: 'Telefon',
      },
      email: {
        label: 'Email',
      },
      emailConfirmation: {
        label: 'Email (wiederholen)',
      },
      street: {
        label: 'Straße, Nr.',
      },
      zipcode: {
        label: 'PLZ',
      },
      city: {
        label: 'Stadt',
      },
      privacy: {
        label: 'Einwilligungserklärung',
        component: {
          label:
            'Ich bin damit einverstanden, dass ich zur Evaluation des Projektes im Nachhinein durch die Wissenschaftsstadt Darmstadt oder die Effizienz:Klasse GmbH kontaktiert werden darf. Zu diesem Zweck bin ich ebenfalls mit einer Weitergabe meiner hinterlegten Anmeldedaten einverstanden.',
        },
      },
    },
  },
  object: {
    title: '',
    step: {
      label: 'Objektdaten',
    },
    formFields: {
      differentAddress: {
        label: 'Objektadresse abweichend',
        component: {
          label: 'Objektadresse abweichend',
        },
      },
      street: {
        label: 'Straße, Nr.',
      },
      zipcode: {
        label: 'PLZ',
      },
      city: {
        label: 'Stadt',
      },
      owner: {
        label: 'Eigentumsverhältnis',
        options: {
          selfOwned: {
            label: 'Eigentümer*in',
          },
          homeownersAssociation: {
            label: 'WEG',
          },
          tenant: {
            label: 'Mieter*in',
          },
        },
      },
      constructionYear: {
        label: 'Baujahr',
      },
      livingSpace: {
        label: 'Wohnfläche',
      },
      protected: {
        label: 'Ensemble-/Denkmalschutz',
        options: {
          yes: {
            label: 'Ja',
          },
          no: {
            label: 'Nein',
          },
        },
      },
      units: {
        label: 'Anzahl Wohneinheiten',
        helperDialog: {
          text: 'Als WE gilt: separater Zugang, eigene Küche, eigenes Bad',
        },
      },
      consumption: {
        label: 'Verbrauchsdaten',
        helperDialog: {
          html: 'Zum Beispiel: <br> 2018: 20.000 kWh Gas <br> 2019: 17.000 kWh Gas <br> ...',
        },
      },
      modernizations: {
        label: 'Modernisierungen',
        helperDialog: {
          html: 'Zum Beispiel <br> 2017: Neue Fenster <br> 2020: Dach gedämmt',
        },
      },
      additionalNotes: {
        label: 'Weitere Hinweise',
      },
      referral: {
        label: 'Wie haben Sie von der Aktion erfahren?',
        options: {
          cityMail: {
            label: 'Postsendung der Stadt',
          },
          billboardFlyer: {
            label: 'Plakate/Flyer',
          },
          media: {
            label: 'Medien',
          },
          recommendation: {
            label: 'Persönliche Empfehlung',
          },
          efzk: {
            label: 'Effizienz:Klasse',
          },
        },
      },
    },
  },
  summary: {
    title: '',
    step: {
      label: 'Zusammenfassung',
    },
  },
  done: {
    title: '',
    step: {
      label: 'Fertig',
    },
  },
  message: {
    success: {
      title: 'Vielen Dank für Ihre Teilnahme!',
    },
  },
  errorMessages: {
    emailConfirmationMismatch: 'Die Email-Adressen stimmen nicht überein.',
    zipRange: 'Die Postleitzahl ist nicht im gültigen Bereich.',
    minValuePositiveNonZero: 'Der Wert muss größer oder gleich 1 sein.',
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */
