import { StepId } from '~/consts/assistant-steps';
import {
  FormCheckboxValueType,
  FormSelectValueType,
  FormTextAreaValueType,
  FormTextFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import {
  buildFormCheckbox,
  buildFormSelect,
  buildFormTextArea,
  buildFormTextField,
} from '@/lib/forms/builder';
import { ValidationType } from '@/consts/validation-types';
import { allowedZipCodes } from '~/consts/validation';

export interface ContactFormStepContext extends AssistantStepContext {
  differentAddress: FormCheckboxValueType;
  street: FormTextFieldValueType;
  zipcode: FormTextFieldValueType;
  city: FormTextFieldValueType;
  owner: FormSelectValueType;
  constructionYear: FormTextFieldValueType;
  livingSpace: FormTextFieldValueType;
  protected: FormCheckboxValueType;
  units: FormTextFieldValueType;
  consumption: FormTextAreaValueType;
  modernizations: FormTextAreaValueType;
  additionalNotes: FormTextAreaValueType;
  referral: FormSelectValueType;
}

const config: StepDefinition = {
  fields: [
    buildFormCheckbox('differentAddress', {
      isVisible: (context) =>
        allowedZipCodes.includes(context.personal.zipcode),
      component: {
        showLabel: true,
      },
      hideLabel: true,
    }),
    buildFormTextField('street', {
      required: true,
      isVisible: (context) =>
        context.object.differentAddress ||
        !allowedZipCodes.includes(context.personal.zipcode),
    }),
    buildFormTextField('zipcode', {
      required: true,
      isVisible: (context) =>
        context.object.differentAddress ||
        !allowedZipCodes.includes(context.personal.zipcode),
      validation: [
        ValidationType.postalCodeDE,
        {
          validator: (value: string) => allowedZipCodes.includes(value),
          errorLabel: 'zipRange',
        },
      ],
    }),
    buildFormTextField('city', {
      required: true,
      isVisible: (context) =>
        context.object.differentAddress ||
        !allowedZipCodes.includes(context.personal.zipcode),
      default: 'Darmstadt',
    }),
    buildFormSelect('owner', {
      required: true,
      component: {
        items: ['selfOwned', 'homeownersAssociation', 'tenant'],
      },
    }),
    buildFormTextField('constructionYear', {
      component: {
        type: 'number',
      },
    }),
    buildFormTextField('livingSpace', {
      component: {
        suffix: 'm²',
        type: 'number',
      },
      validation: [
        {
          validator: (val) => !val || parseInt(val) >= 1,
          errorLabel: 'minValuePositiveNonZero',
        },
      ],
    }),
    buildFormSelect('protected', {
      required: true,
      component: {
        items: ['yes', 'no'],
      },
    }),
    buildFormTextField('units', {
      required: true,
      showHelperDialog: true,
      component: {
        type: 'number',
        min: 1,
      },
      validation: [
        {
          validator: (val) => parseInt(val) >= 1,
          errorLabel: 'minValuePositiveNonZero',
        },
      ],
    }),
    buildFormTextArea('consumption', {
      component: {
        'auto-grow': true,
        clearable: true,
        rows: 4,
      },
      showHelperDialog: true,
    }),
    buildFormTextArea('modernizations', {
      component: {
        'auto-grow': true,
        clearable: true,
        rows: 4,
      },
      showHelperDialog: true,
    }),
    buildFormTextArea('additionalNotes', {
      component: {
        'auto-grow': true,
        clearable: true,
        rows: 4,
      },
    }),
    buildFormSelect('referral', {
      required: true,
      component: {
        items: [
          'cityMail',
          'billboardFlyer',
          'media',
          'recommendation',
          'efzk',
        ],
      },
    }),
  ],
  id: StepId.OBJECT,
};

export default config;
