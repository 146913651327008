import done from '~/steps/done';
import personal from '~/steps/personal';
import object from '~/steps/object';
import summary from '~/steps/summary';

// Add the imported StepDefinition from the steps in the order
// the steps should be shown in the assistant.
const steps = [personal, object, summary, done];

// Add the imported context interface for the steps
export interface AssistantContext {
  personal;
  object;
  summary;
  done;
}

export default steps;
