export const allowedZipCodes = [
  '64283',
  '64285',
  '64287',
  '64289',
  '64291',
  '64293',
  '64295',
  '64297',
];
