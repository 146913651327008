

















import { defineComponent, onMounted, ref } from '@vue/composition-api';

let initialLoad = true;

export default defineComponent({
  props: {
    hasRequiredFields: {
      default: false,
      type: Boolean,
    },
    title: {
      default: '',
      type: String,
    },
    valid: {
      default: true,
      type: Boolean,
    },
  },
  setup: () => {
    const scrollMarker = ref<null | HTMLElement>(null);
    const scrollToStepTop =
      process.env.VUE_APP_ENABLE_SCROLL_TO_STEP_TOP !== 'false';

    if (scrollToStepTop) {
      onMounted(() => {
        if (scrollMarker.value && !initialLoad) {
          setTimeout(() => {
            const elementOffset =
              (scrollMarker.value?.getBoundingClientRect().top ?? 0) +
              (window.pageYOffset || document.documentElement.scrollTop) -
              120;
            window.scrollTo({
              behavior: 'smooth',
              top: elementOffset,
            });
          }, 100);
        }

        initialLoad = false;
      });
    }

    return { scrollMarker };
  },
});
