import { StepId } from '~/consts/assistant-steps';
import {
  FormSelectValueType,
  FormTextFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import {
  buildFormCheckbox,
  buildFormSelect,
  buildFormTextField,
} from '@/lib/forms/builder';
import { ValidationType } from '@/consts/validation-types';

export interface PersonalStepContext extends AssistantStepContext {
  salutation: FormSelectValueType;
  titel: FormSelectValueType;
  firstname: FormTextFieldValueType;
  lastname: FormTextFieldValueType;
  phone: FormTextFieldValueType;
  email: FormTextFieldValueType;
  street: FormTextFieldValueType;
  zipcode: FormTextFieldValueType;
  city: FormTextFieldValueType;
}

const config: StepDefinition = {
  fields: [
    buildFormSelect('salutation', {
      required: true,
      component: {
        items: ['female', 'male', 'nonbinary', 'married', 'family'],
      },
    }),
    buildFormSelect('title', {
      component: {
        items: ['none', 'dr', 'prof', 'profDr'],
      },
    }),
    buildFormTextField('firstname', {
      required: true,
    }),
    buildFormTextField('lastname', {
      required: true,
    }),
    buildFormTextField('phone', {
      required: true,
      validation: [ValidationType.phoneNumber],
    }),
    buildFormTextField('email', {
      component: {
        type: 'email',
      },
      required: true,
      validation: [ValidationType.email],
    }),
    buildFormTextField('street', {
      required: true,
    }),
    buildFormTextField('zipcode', {
      required: true,
      validation: [ValidationType.postalCodeDE],
    }),
    buildFormTextField('city', {
      required: true,
    }),
    buildFormCheckbox('privacy', {
      component: {
        showLabel: true,
      },
    }),
  ],

  id: StepId.PERSONAL,
};

export default config;
