import VueI18n from 'vue-i18n';
import axios from 'axios';
import { StepId } from '~/consts/assistant-steps';
import { StepDefinition } from '@/interfaces/step-definition';
import { StepTypes } from '@/consts/step-types';
import { AssistantContext } from '~/steps/steps';
import { allowedZipCodes } from '~/consts/validation';

const fieldMapping = {
  personal: {
    salutation: {
      name: 'customfield_10030',
      options: {
        female: '10022',
        male: '10023',
        nonbinary: '10020',
        married: '10021',
        family: '10027',
      },
    },
    title: {
      name: 'customfield_10031',
      options: {
        none: '',
        dr: '10024',
        prof: '10025',
        profDr: '10026',
      },
    },
    firstname: { name: 'customfield_10032' },
    lastname: { name: 'customfield_10033' },
    phone: { name: 'customfield_10040' },
    email: { name: 'customfield_10042' },
    street: { name: 'customfield_10035' },
    zipcode: { name: 'customfield_10052' },
    city: { name: 'customfield_10036' },
    privacy: {
      name: 'customfield_10147',
      checked: {
        yes: '10103',
        no: '10104',
      },
    },
  },
  object: {
    differentAddress: { name: 'addressCB' },
    street: { name: 'customfield_10034' },
    zipcode: { name: 'customfield_10053' },
    city: { name: 'customfield_10037' },
    owner: {
      name: 'customfield_10099',
      options: {
        selfOwned: '10091',
        homeownersAssociation: '10092',
        tenant: '10093',
      },
    },
    constructionYear: { name: 'customfield_10050' },
    livingSpace: { name: 'customfield_10095' },
    protected: {
      name: 'customfield_10094',
      options: {
        yes: '10098',
        no: '10080',
      },
    },
    units: { name: 'customfield_10062' },
    consumption: { name: 'customfield_10097' },
    modernizations: { name: 'customfield_10098' },
    additionalNotes: { name: 'customfield_10096' },
    referral: {
      name: 'customfield_10100',
      options: {
        cityMail: '10084',
        billboardFlyer: '10085',
        media: '10086',
        recommendation: '10087',
        efzk: '10089',
      },
    },
  },
};

const mapFields = (assistantContext: AssistantContext, i18n: VueI18n) => {
  const output = {};

  for (const step in assistantContext) {
    for (const field in assistantContext[step]) {
      if (!fieldMapping[step][field]) {
        continue;
      }

      let val = '';

      if ('options' in fieldMapping[step][field]) {
        for (const option in fieldMapping[step][field].options) {
          const t = i18n.t(
            `${step}.formFields.${field}.options.${option}.label`,
          );

          if (t === assistantContext[step][field]) {
            val = fieldMapping[step][field].options[option];
            break;
          }
        }
      } else if ('checked' in fieldMapping[step][field]) {
        val = assistantContext[step][field]
          ? fieldMapping[step][field].checked.yes
          : fieldMapping[step][field].checked.no;
      } else {
        val = assistantContext[step][field];
      }

      output[fieldMapping[step][field].name] = val;
    }
  }

  return output;
};

const config: StepDefinition = {
  id: StepId.SUMMARY,
  type: StepTypes.SUMMARY,

  submit: ({ i18n, assistantContext }) => {
    if (!allowedZipCodes.includes(assistantContext.personal.zipcode)) {
      assistantContext.object.differentAddress = true;
    }

    const payload = mapFields(assistantContext, i18n);

    return axios.post(process.env.VUE_APP_SUBMIT_URL, payload);
  },
};

export default config;
