import { StepId } from '~/consts/assistant-steps';
import { StepDefinition } from '@/interfaces/step-definition';
import { StepTypes } from '@/consts/step-types';

// This is a very simple step that just shows a success message
// If isFinal is true, the data from browser's local storage+
// will be removed and the DefaultDone.vue default component will
// be shown (instead of DefaultStep.vue), unless you add your
// component here.
const config: StepDefinition = {
  id: StepId.DONE,
  type: StepTypes.FINAL,
};

export default config;
